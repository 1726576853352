type SiteConfigType = {
  siteName: string;
  ceo : string;
  ciso : string;
  address : string;
  copyRight : string;
  siteUrl : string;
  domain : string;
  call : string;
}


export const SITE_CONFIG : SiteConfigType = {
  siteName: '한국중소벤처기업유통원',
  ceo : '이태식',
  ciso: '이성복',
  address : '서울특별시 양천구 목동동로 309 한국중소벤처기업유통원',
  copyRight : 'COPYRIGHT © Korea SMEs & Startup Distribution & Marketing Agency. ALL RIGHTS RESERVED.',
  siteUrl : 'https://www.kodma.or.kr',
  domain : 'kodma.or.kr',
  call: '1522-6009'
}
